import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
            config.one is busy<br /> working on smarter solutions powered by AI
        </p>
      </header>
    </div>
  );
}

export default App;
